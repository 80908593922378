import { ResponsiveEmbed } from "../../../../src/components/blog/ResponsiveEmbed";
import { SpicyTake } from "../../../../src/components/blog/Titles";
import { Dialogue, FigureLabel, Indented } from "../../../../src/components/blog/Text";
import TvCard from "../../../../src/components/blog/TvCard";
import YouTube from "../../../../src/components/blog/YouTube";
import BlogImage from "../../../../src/components/blog/BlogImage";
import * as React from 'react';
export default {
  ResponsiveEmbed,
  SpicyTake,
  Dialogue,
  FigureLabel,
  Indented,
  TvCard,
  YouTube,
  BlogImage,
  React
};