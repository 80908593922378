module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Gianluca Belvisi's Blog","short_name":"Gianluca Belvisi","start_url":"/","background_color":"#131313","theme_color":"#131313","display":"minimal-ui","icon":"src/assets/images/icon.jpg","cache_busting_mode":"query","legacy":true,"orientation":"portrait","screenshots":[{"src":"src/assets/images/icon.jpg","sizes":"563x563","type":"image/jpg"}],"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"c71690c5ad6f94c137da0db35a329d04"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":1200}}],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/opt/build/repo"},
    }]
