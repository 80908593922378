import { FigureLabel } from "../../../../src/components/blog/Text";
import { Listen } from "../../../../src/components/blog/YouTubeAudio";
import Quote from "../../../../src/components/blog/Quote";
import Nsfw from "../../../../src/components/blog/Nsfw";
import * as React from 'react';
export default {
  FigureLabel,
  Listen,
  Quote,
  Nsfw,
  React
};