import YouTube from "../../../../src/components/blog/YouTube";
import LinkButton from "../../../../src/components/Button";
import Poll from "../../../../src/components/blog/Poll";
import { FigureLabel, Pony } from "../../../../src/components/blog/Text";
import * as React from 'react';
export default {
  YouTube,
  LinkButton,
  Poll,
  FigureLabel,
  Pony,
  React
};