import { ResponsiveEmbed } from "../../../../src/components/blog/ResponsiveEmbed";
import { Dialogue, FigureLabel, Pony } from "../../../../src/components/blog/Text";
import BlogImage from "../../../../src/components/blog/BlogImage";
import Poll from "../../../../src/components/blog/Poll";
import TextBox from "../../../../src/components/blog/Boxes";
import * as React from 'react';
export default {
  ResponsiveEmbed,
  Dialogue,
  FigureLabel,
  Pony,
  BlogImage,
  Poll,
  TextBox,
  React
};