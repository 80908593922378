import BlogImage from "../../../../src/components/blog/BlogImage";
import YouTube from "../../../../src/components/blog/YouTube";
import { ResponsiveEmbed } from "../../../../src/components/blog/ResponsiveEmbed";
import { Dialogue, FigureLabel, Formula, Indented, Pony } from "../../../../src/components/blog/Text";
import * as React from 'react';
export default {
  BlogImage,
  YouTube,
  ResponsiveEmbed,
  Dialogue,
  FigureLabel,
  Formula,
  Indented,
  Pony,
  React
};