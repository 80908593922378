import Poll from "../../../../src/components/blog/Poll";
import { ProfitBox } from "../../../../src/components/blog/Boxes";
import { FigureLabel, Pony } from "../../../../src/components/blog/Text";
import Quote from "../../../../src/components/blog/Quote";
import YouTube from "../../../../src/components/blog/YouTube";
import * as React from 'react';
export default {
  Poll,
  ProfitBox,
  FigureLabel,
  Pony,
  Quote,
  YouTube,
  React
};