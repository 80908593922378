import Poll from "../../../../src/components/blog/Poll";
import BlogImage from "../../../../src/components/blog/BlogImage";
import { ResponsiveEmbed } from "../../../../src/components/blog/ResponsiveEmbed";
import YouTube from "../../../../src/components/blog/YouTube";
import { Dialogue, FigureLabel, Indented, Pony } from "../../../../src/components/blog/Text";
import { ShakyTitle } from "../../../../src/components/blog/Titles";
import UnicornButton from "../../../../src/components/blog/UnicornButton";
import * as React from 'react';
export default {
  Poll,
  BlogImage,
  ResponsiveEmbed,
  YouTube,
  Dialogue,
  FigureLabel,
  Indented,
  Pony,
  ShakyTitle,
  UnicornButton,
  React
};