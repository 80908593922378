import Poll from "../../../../src/components/blog/Poll";
import TextBox from "../../../../src/components/blog/Boxes";
import { Batman } from "../../../../src/components/blog/Grids";
import { FigureLabel } from "../../../../src/components/blog/Text";
import YouTube from "../../../../src/components/blog/YouTube";
import FilmCard from "../../../../src/components/blog/FilmCard";
import * as React from 'react';
export default {
  Poll,
  TextBox,
  Batman,
  FigureLabel,
  YouTube,
  FilmCard,
  React
};