import TextBox from "../../../../src/components/blog/Boxes";
import { BlogSubTitle, Dialogue, FigureLabel, MarginBottom, Pony } from "../../../../src/components/blog/Text";
import PostFooter from "../../../../src/components/PostFooter";
import * as React from 'react';
export default {
  TextBox,
  BlogSubTitle,
  Dialogue,
  FigureLabel,
  MarginBottom,
  Pony,
  PostFooter,
  React
};