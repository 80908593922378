import { Dialogue, FigureLabel } from "../../../../src/components/blog/Text";
import Spoiler from "../../../../src/components/blog/Spoiler";
import Poll from "../../../../src/components/blog/Poll";
import { Books2022 } from "../../../../src/components/blog/PhotoStack";
import YouTube from "../../../../src/components/blog/YouTube";
import { ResponsiveEmbed } from "../../../../src/components/blog/ResponsiveEmbed";
import * as React from 'react';
export default {
  Dialogue,
  FigureLabel,
  Spoiler,
  Poll,
  Books2022,
  YouTube,
  ResponsiveEmbed,
  React
};